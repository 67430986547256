<template>
  <div>
    <v-layout wrap justify-center py-lg-12>
      <v-flex xs12 py-4t text-center pt-8>
        <span
          class="bold"
          style="
            font-size: 40px;
            border-bottom: 2px solid #ff8533;
            font-size: 30px;
            padding-left: 20px;
            padding-right: 20px;
            
          "
          >ADMINISTRATION</span
        >
      </v-flex>
      <v-flex xs12 lg10>
        <v-layout wrap pt-4 pt-lg-10 justify-center>
          <v-flex
            xs12
            sm6
            md3
            v-for="(item, i) in staffData"
            :key="i"
            pt-4
            pt-sm-0
            pa-4
          >
            <v-layout wrap>
              <v-flex xs12>
                <v-img
                  class="mx-auto"
                  height="250px"
                  width="250px"
                  :src="require('../assets/images/staff/' + item.image)"
                >
                </v-img>
              </v-flex>
              <v-flex xs12 text-center>
                <span class="bold">{{ item.name }}</span>
              </v-flex>
              <v-flex xs12 text-center>
                <span class="regular">{{ item.position }}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      staffData: [
        {
          image: "Bishop.jpg",
          name: "Rt. Rev. Dr. Sebastian Thekethecheril",
          position: "PATRON",
        },
        {
          image: "co.jpg",
          name: "Fr. Dr. Antoney  George Pattaparambil",
          position: "CORPORATE MANAGER",
        },
        {
          image: "tom.jpg",
          name: "Rev. Fr. Tom Jose",
          position: "Local Manager",
        },
        {
          image: "newPrincipal.jpeg",
          name: "MR .Andrews Daniel",
          position: "Principal",
        },
      ],
    };
  },
};
</script>