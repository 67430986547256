<template>
    <div>
        <v-layout wrap>
            <v-flex>

            </v-flex>
        </v-layout>
        <!-------------------------------->
       <v-layout wrap class="hidden-xs-only">
           <v-flex>
            <!-- <v-img
             gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
            
              src="../assets/images/gallery/a0.jpg" class="align-center white--text" height="700px" > -->
            <v-carousel hide-delimiters  cycle>
                <v-carousel-item
               
                    v-for="(items,i) in items"
                    :key="i"
                    :src="getImageURL(items.src)"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                    gradient="to bottom, rgba(0,0,0,.4), rgba(0,0,0,.8)">
                
                <v-layout pt-16 wrap>
                    <v-flex xs12 pt-16 mt-16 text-center>
                    <span class="bold" style="font-size:40px; color:white ">WELCOME TO<br/> B.B.M.T.T.I MUNDAKAYAM </span> <br> <br>
                    <!-- <span class="bold" style="font-size:40px ">TEACHERS TRAINING INSTITUTE</span> -->
                    <span>
                        <a href="https://ncte.gov.in/Website/Index.aspx" target="blank" style="text-decoration:none">
                        <v-btn 
                        width="200px" 
                        rounded 
                        outlined 
                        class="bold hv" 
                        color="warning">
                            NCTE  &nbsp;<v-icon small>mdi-arrow-right</v-icon>
                        </v-btn>
                        </a>
                    </span>
                    </v-flex>
                </v-layout>
                </v-carousel-item>  
            </v-carousel>
            <!-- </v-img> -->
           </v-flex>
       </v-layout>
        <v-layout wrap class="hidden-sm-and-up" >
           <v-flex>
            <v-img 
            height="400px"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
            src="../assets/images/gallery/a0.jpg" class="align-center white--text">
            <v-layout wrap>
                <v-flex xs12 text-center>
                 <span class="bold" style="font-size:40px ">B.B.M.T.T.I MUNDAKAYAM </span> <br> <br>
                 <!-- <span class="bold" style="font-size:40px ">TEACHERS TRAINING INSTITUTE</span> -->
                  <span>
                     <a href="https://ncte.gov.in/Website/Index.aspx" target="blank"  style="text-decoration:none">
                     <v-btn width="200px" rounded class="bold" color="error">
                         NCTE  &nbsp;<v-icon small>mdi-arrow-right</v-icon>
                     </v-btn>
                     </a>
                 </span>
                </v-flex>
            </v-layout>
            </v-img>
           </v-flex>
       </v-layout>
    </div>
</template>
<script>
  export default {
    data () {
      return {
        items: [
				{
					src: "img1.jpg",	
				},
				{
					src: "img2.jpg",	
				},
				{
					src: "img3.jpg",	
				},
				{
					src: "img4.jpg",	
				},
                {
					src: "img5.jpg",	
				},
			],
		};
	},
	methods: {
		getImageURL(src) {
			let images = require.context("../assets/images/school");
			return images("./" + src);
		},
	},
};
</script>
<style>
.hv:hover{
    background-color: #ffeb33;
}
</style>