<template>
  <div>
    <Banner />
    <!-- <OurVision /> -->
    <Aboutus />
    <Course />
    <!-- <CourseDetail  /> -->
    
    <Mteam />
    <!-- <Faculties /> -->
    <!-- <Advisory /> -->
    <!-- <CourseDetail class="hidden-xs-only" /> -->
    <!-- <Partner /> -->
  </div>
</template>
<script>
import Banner from "./banner";
// import OurVision from "./ourVision";
import Aboutus from "./aboutUs";
import Course from "./ourCourse";
// import CourseDetail from "./courseDetails";
import Mteam from "./managementTean";
// import Faculties from "./faculties";
// import Advisory from "./advisory";
// import Partner from "./trainingPartner";

export default {
  components: {
    Banner,
    // OurVision,
    Aboutus,
    Course,
    // CourseDetail,
    Mteam,
    // Faculties,
    // Advisory,
    // Partner,
  },
};
</script>